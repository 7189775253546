<template>
  <template v-if="loading">
    <div class="menu">
      <a href="#" class="button user-data">
        <span class="avatar inline-flex">
          <span class="material-icons" style="background-color: initial">
            <div
              class="size-9 shrink-0 bg-zinc-300 dark:bg-zinc-800 rounded-full animate-pulse"
              style="width: 100%; height: 100%"
            ></div>
          </span>

          <span class="text flex items-center username w-[60%]">
            <span class="flex flex-col text-xs overflow-hidden text-ellipsis">
              <span
                class="overflow-hidden text-ellipsis"
                style="width: 110px; margin-top: 4px"
                ><div
                  class="h-4 bg-zinc-300 dark:bg-zinc-800 rounded-md animate-pulse w-1/2"
                ></div
              ></span>
              <span
                class="overflow-hidden text-ellipsis"
                style="width: 80px; margin-top: 4px"
                ><div
                  class="h-4 bg-zinc-300 dark:bg-zinc-800 rounded-md animate-pulse w-1/2"
                ></div
              ></span>
            </span>
          </span>
        </span>
      </a>
    </div>

    <div class="menu count">
      <a href="#">
        <span>
          <div
            class="h-5 bg-zinc-300 dark:bg-zinc-800 rounded-md animate-pulse w-1/2"
            style="width: 80px"
          ></div>
        </span>
      </a>
      <a href="#">
        <span>
          <div
            class="h-5 bg-zinc-300 dark:bg-zinc-800 rounded-md animate-pulse w-1/2"
            style="width: 80px"
          ></div>
        </span>
      </a>
    </div>
  </template>

  <template v-else>
    <div class="menu">
      <router-link
        @click="hideSidebar()"
        :to="`/c/${username}`"
        class="button user-data"
      >
        <span class="avatar inline-flex">
          <span class="material-icons">
            <UserAvatar :avatar="avatar" />
          </span>
        </span>

        <span class="text flex items-center username w-[60%]">
          <span class="flex flex-col text-xs overflow-hidden text-ellipsis">
            <span class="overflow-hidden text-ellipsis">{{ name }}</span>
            <span class="overflow-hidden text-ellipsis">@{{ username }}</span>
          </span>
        </span>
      </router-link>
    </div>

    <div class="menu count">
      <router-link :to="`/${username}/followers`">
        <span>
          <FormatCount
            :count="followers"
            class="text-black dark:text-white"
          />&nbsp;<span>Follower<span v-if="followers !== 1">s</span></span>
        </span>
      </router-link>
      <router-link :to="`/${username}/following`">
        <span>
          <FormatCount
            class="text-black dark:text-white"
            :count="following"
          />&nbsp;<span>Following</span>
        </span>
      </router-link>
    </div>
  </template>

  <div class="menu">
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Discover"
      :icon="discoverIcon"
      to="/"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Bursts"
      :icon="burstsIcon"
      to="/b"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Trending"
      :icon="trendingIcon"
      to="/trending-videos"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Recently Added"
      :icon="ClockCountdown"
      to="/recent-videos"
    />
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Following"
      :icon="followingIcon"
      to="/following"
    />
    <span style="display: none">
      <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Watch Later"
        :icon="watchLaterIcon"
        to="/watch-later"
      />
    </span>
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="My Media"
      :icon="myChannelIcon"
      :to="`/media/videos`"
      paths="/media/videos, /media/bursts"
    />
  </div>

  <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="History"
        :icon="historyIcon"
        to="/history"
      /> -->

  <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Follows"
        :icon="followsIcon"
        to="/follows"
      /> -->

  <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Liked Videos"
        :icon="likedVideosIcon"
        to="/liked-videos"
      /> -->

  <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Watch Later"
        :icon="watchLaterIcon"
        to="/watch-later"
      /> -->

  <div class="menu">
    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="My channel"
        :icon="aboutIcon"
        :to="`/c/${username}`"
      /> -->
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Settings"
      :icon="settingsIcon"
      to="/account"
      paths="
                /account,
                /account/details,
                /account/notifications,
                /account/preferences,
                /account/privacy-and-settings,
                /account/violations,
                /account/mutes-and-blocks,
                /policy/terms-and-conditions,
                /policy/privacy,
                /policy/community-guidelines
          "
    />
    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="About"
        :icon="aboutIcon"
        to="/about"
      /> -->

    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Support"
        :icon="supportIcon"
        to="/team"
      /> -->
  </div>

  <div class="menu flex-grow">&nbsp;</div>

  <div class="theme-switch">
    <DarkModeSwitch />
  </div>

  <div class="menu log-out">
    <SideBarButton
      @linkClicked="
        () => {
          logOut();
          hideSidebar();
        }
      "
      label="Log Out"
      :icon="logoutIcon"
    />
  </div>

  <div class="menu footer text-xs">
    <span class="material-icons"></span>

    <span class="text">
      <span class="flex flex-col gap-2 m-4">
        <span class="flex flex-row gap-4">
          <a href="https://help.parler.com/hc/90853520" target="_blank">Help</a>
        </span>
        <span class="text-gray-400">Powered by Parler &copy; 2025</span>
        <span class="text-gray-400">Version: {{ appVersion }}</span>
      </span>
    </span>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useSidebarStore } from "@/stores/sidebarStore.tsx";
import { useUserStore } from "@/stores/userStore.tsx";
import SideBarButton from "./SideBarButton.vue";
import discoverIcon from "@/assets/playtv/icons/sidebar/discover-icon.svg";
import ClockCountdown from "@/assets/playtv/icons/ClockCountdown.svg";
import trendingIcon from "@/assets/playtv/icons/sidebar/trending-icon.svg";
import burstsIcon from "@/assets/playtv/icons/sidebar/bursts-icon.svg";
import followingIcon from "@/assets/playtv/icons/sidebar/following.svg";
import watchLaterIcon from "@/assets/playtv/icons/watch-later-icon.svg";
import myChannelIcon from "@/assets/playtv/icons/sidebar/my-channel-icon.svg";
import settingsIcon from "@/assets/playtv/icons/sidebar/settings-icon.svg";
import logoutIcon from "@/assets/playtv/icons/sidebar/logout-icon.svg";
import DarkModeSwitch from "@/components/sidebar/DarkModeSwitch.vue";
import { useLogoutConfirmationModal } from "@/components/logout/useLogoutConfirmationModal.js";
import FormatCount from "../FormatCount.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import "vue-toast-notification/dist/theme-sugar.css";

const sidebarStore = useSidebarStore();
const appVersion = import.meta.env.VITE_APP_VERSION;

const hideSidebar = () => sidebarStore.hide();

const hideSidebarOnMobile = () => {
  const width = window.innerWidth;

  if (width < 1080) {
    sidebarStore.hide();
  }
};

const userStore = useUserStore();

const username = computed(() => {
  return userStore.username;
});

const followers = computed(() => {
  return userStore.followers;
});

const following = computed(() => {
  return userStore.following;
});

const name = computed(() => {
  return userStore.name;
});

const avatar = computed(() => {
  return userStore.avatar;
});

const loading = computed(() => {
  return userStore.loading;
});

const { openLogoutConfirmationModal } = useLogoutConfirmationModal();

const logOut = () => {
  openLogoutConfirmationModal();
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/mixins.scss";
@import "./css/sidebar.scss";
</style>
<style lang="scss">
@import "./css/sidebar-unscoped.scss";
</style>
