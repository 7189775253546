<template>
  <div id="bursts">
    <router-link
      class="flex items-center justify-start p-4 pb-4 space-x-4 select-none"
      :to="burstUrl"
    >
      <span class="image-container">
        <img :src="burstIcon" alt="BurstIcon" />
      </span>
      <p class="text-lg font-bold m-0 text-black dark:text-white">Bursts</p>
    </router-link>

    <BurstScroller
      :loading="loading"
      :loadingMore="loadingMore"
      :bursts="bursts"
      :number-of-bursts="numberOfBursts"
      :nextCursor="nextCursor"
      :end-of-bursts="endOfBursts"
      @load-more-bursts="loadBursts"
    />

    <div v-if="!loading && bursts.length === 0" class="text-center">
      No bursts
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import burstIcon from "@/assets/playtv/icons/burst-icon.svg";
import { transformToVideo } from "@/types/Video.tsx";
import BurstScroller from "@/components/feed/BurstScroller.vue";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  onDashboard: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = computed(() => props.loading);
const loadingMore = ref(false);
const nextCursor = ref(null);
const bursts = ref([]);
const endOfBursts = ref(false);
const numberOfBursts = ref(20);

const burstUrl = computed(() => {
  return `/b/${bursts.value.length > 0 ? bursts.value[0].id : ""}`;
});

// Load videos
const loadBursts = async () => {
  if (loadingMore.value || endOfBursts.value) {
    return;
  }

  if (nextCursor.value !== null) {
    loadingMore.value = true;
  }

  try {
    // Fetch Burst IDs
    const cursorParam = nextCursor.value ? `?cursor=${nextCursor.value}` : "";
    const {
      data: { data: videoDataIds, next_cursor: cursor },
    } = await fetchBurstIds(cursorParam);

    // Update State
    updateBurstState(videoDataIds, cursor);

    if (videoDataIds.length === 0) {
      emits("loadedVideos");
      return;
    }

    // Fetch Video Data
    const videoData = await fetchVideoData(videoDataIds);
    const profileData = await fetchProfileData(videoData);

    // Transform Burst Data
    const newBursts = transformBursts(videoDataIds, videoData, profileData);

    if (newBursts.length === 0 && nextCursor.value === null) {
      loadingMore.value = false;
      loadBursts();
      return;
    }

    updateBurstList(newBursts);
    emits("loadedVideos");
  } catch (e) {
    console.error(e);
  } finally {
    loadingMore.value = false;
  }
};

// Function to fetch burst IDs
const fetchBurstIds = async (cursorParam) => {
  return await apiBackendAuthAxios.get(`/bursts/all`, {
    params: {
      cursor: cursorParam ? cursorParam.split("=")[1] : undefined,
      watched: true,
    },
  });
};

// Function to fetch video data
const fetchVideoData = async (videoDataIds) => {
  const {
    data: { data: videoData },
  } = await apiBackendAuthAxios.post(`/posts/map`, {
    data: videoDataIds.map((v) => v.ulid),
    responseType: "videos",
  });
  return videoData;
};

// Function to fetch profile data
const fetchProfileData = async (videoData) => {
  const { data } = await apiBackendAuthAxios.post("/profile", {
    ulids: [...new Set(videoData.map((v) => v.user.userId))],
  });
  return data;
};

// Function to update burst state variables
const updateBurstState = (videoDataIds, cursor) => {
  numberOfBursts.value = videoDataIds.length;
  nextCursor.value = cursor;

  if (cursor === null) {
    endOfBursts.value = true;
  }
};

// Function to transform bursts to desired format
const transformBursts = (videoDataIds, videoData, profileData) => {
  return (
    videoDataIds
      .map((v) => v.ulid)
      .filter((v1) => videoData.some((v2) => v1 === v2.id))
      .map((v1) => videoData.find((v2) => v1 === v2.id))
      .map((v) => transformToVideo(v, profileData[0].data))
      // Filter out bursts that are already in the list
      .filter((v) => !bursts.value.includes(v.id))
  );
};

// Function to update the burst list
const updateBurstList = (newBursts) => {
  bursts.value =
    bursts.value.length === 0 ? newBursts : [...bursts.value, ...newBursts];
};

onMounted(() => {
  loadBursts();
});
</script>
