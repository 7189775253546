import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import vue3GoogleLogin from "vue3-google-login";
import Vapor from "laravel-vapor";

// import "@/assets/styles/index.scss";
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
window.Vapor = Vapor;

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
pinia.use(
  PiniaSharedState({
    enable: false,
    initialize: false,
  })
);

import App from "./App.vue";
import { createVfm } from "vue-final-modal";

const app = createApp(App);

import router from "./router";
import "./assets/main.scss";
import "vue-final-modal/style.css";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import VueMixpanel from "vue-mixpanel";
import { useUserStore } from "./stores/userStore";

const vfm = createVfm();
app.use(pinia).use(vfm).use(router);

// Initialize Google Analytics with VueGtag
app.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_GA_MEASUREMENT_ID,
    },
  },
  router
);

// Modify Sentry initialization
Sentry.init({
  app,
  dsn: "https://39230d7a813ee440dd013b1ac3d71f49@playtv-sentry.par.pw/2",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
      },
    }),
  ],
  // Disable in localhost
  enabled: !window.location.hostname.includes("localhost"),

  // Add user information
  beforeSend(event) {
    const userStore = useUserStore();

    if (userStore.email || userStore.username) {
      event.user = {
        id: userStore.userId,
        email: userStore.email,
        username: userStore.username,
      };
    }

    return event;
  },

  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/platv\.parler\.com\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  scope: "email profile openid",
});

app.use(VueMixpanel, {
  token: import.meta.env.VITE_MIXPANEL_TOKEN,
});

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

app.use(FloatingVue);
import { vTooltip, vClosePopper, Tooltip } from "floating-vue";

app.directive("tooltip", vTooltip);
app.directive("close-popper", vClosePopper);
app.component("VTooltip", Tooltip);

// Mount the app
app.mount("#app");
